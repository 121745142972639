import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints'
import useCardContext from './useCardContext'
import brushStrokeSmall from '../../../assets/brushStrokeSmall50.png'
import brushStrokeSmallFlipped from '../../../assets/brushStrokeSmallFlipped50.png'

const StyledCardHeading = styled.h3`
    grid-area:heading;
    font-size: 2.5rem;
    font-weight: 500;
    background-image: ${props=>props.reverse?`url(${brushStrokeSmall})`:`url(${brushStrokeSmallFlipped})`};
    background-size:contain;
    background-repeat:no-repeat;
    
   @media screen and (${breakpoints.tabletLandscapeUp}) {
        margin-top: 1.8rem;
        font-size:3.25rem;
   }
`;

function CardHeading({children}) {

    const {reverse} = useCardContext()

    return (
        <StyledCardHeading reverse={reverse}>
            {children}
        </StyledCardHeading>
    );
};

export default CardHeading;
