import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from '../Img'
import breakpoints from '../../styles/breakpoints'
import useCardContext from './useCardContext'

const CardImgWrapper = styled.div`
    grid-area:image;
    min-width: 50%;    
`

const StyledCardImage = styled(Img)`

    height:100%;

    img{
        object-fit:cover;

        @media screen and (${breakpoints.tabletUp}){
            max-height:500px;
        }
    }
`

function CardThumbs({ imgSources = [] }) {

    useCardContext()

    return (
        <CardImgWrapper>
            {imgSources.map((imgSrc, index) =>
                <StyledCardImage alt="" key={index} src={imgSrc} />
            )}
        </CardImgWrapper>
    )
}

CardThumbs.propTypes = {
    imgSources: PropTypes.array
}

export default CardThumbs
