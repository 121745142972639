import React from 'react'
import styled from 'styled-components'
import useCardContext from './useCardContext'

const StyledCardBody = styled.div`
    grid-area:body; 
    min-width:0;
`;

function CardBody({ children }) {
    useCardContext()
    return (
        <StyledCardBody style={{ backgroundColor: 'transparent' }}>
            {children}
        </StyledCardBody>
    )
}

export default CardBody