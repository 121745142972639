import React from 'react'
import PropTypes from 'prop-types'
import CardBody from './CardBody'
import CardHeading from './CardHeading'
import CardThumbs from './CardThumbs'
import styled, { css } from 'styled-components'
import { CardContext } from './useCardContext'
import breakpoints from '../../styles/breakpoints'

const horizontalGrid = (props) => css`
    grid-template-areas: ${props.$reverse ?
        `"image heading"
             "image body"`
        :
        `"heading image"
             "body image"`
    };

    grid-auto-rows: auto 1fr;
    grid-template-columns: 1fr 1fr;

    grid-column-gap: 1rem;
    grid-row-gap: 1rem;

    @media (${breakpoints.tabletLandscapeUp}) {
        grid-column-gap: 3rem;
    }

    &+& {
    margin-top: 4rem;
    }
`

const verticalGrid = css`
    grid-template-areas:"image"  
                        "heading"
                        "body";

    grid-auto-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    &+& {
        margin-top: 2rem;
    }
`

//default is vertical in large screen and horizontal in small screen
const defaultGrid = css`
    ${verticalGrid}
    @media screen and (${breakpoints.tabletUp}){
        ${horizontalGrid}
    }
`

const StyledCard = styled.div`
    display:grid;
    
    ${props => {
        if (props.$orientation) {
            return props.$orientation === 'vertical' ? verticalGrid : horizontalGrid(props)
        }
        else {
            return defaultGrid
        }
    }}
`

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 80%;
`;

const Card = ({ children, className, reverse = false, orientation = null }) => {

    React.useEffect(function getOrientation() {

    })

    return (
        <CardContext.Provider value={{ reverse: reverse }}>
            <StyledCard className={className} $orientation={orientation} $reverse={reverse}>{children}</StyledCard>
        </CardContext.Provider>
    )
}

Card.propTypes = {
    imgSources: PropTypes.arrayOf(PropTypes.string),
    reverse: PropTypes.bool,
    orientation: PropTypes.oneOf(['horizontal', 'vertical', null])
}

Card.Thumbs = CardThumbs;
Card.Body = CardBody;
Card.Heading = CardHeading;
Card.Content = CardContent;

export default Card
