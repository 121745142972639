import React from "react"
import Head from "../components/Head"
import styled from "styled-components"
import Card from "../components/card/Card"
import breakpoints from "../styles/breakpoints"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { useStaticQuery, graphql } from "gatsby"

const AboutPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;

    @media (${breakpoints.tabletLandscapeUp}) {
        width: 100%;
        padding: 2rem 0 2rem 0;
    }
`

const AboutPage = () => {
    const cardsData = useStaticQuery(graphql`
        query {
            allContentfulAboutPageCard(
                sort: { fields: [createdAt], order: ASC }
            ) {
                edges {
                    node {
                        id
                        heading
                        image {
                            file {
                                url
                            }
                        }
                        body {
                            json
                        }
                    }
                }
            }
        }
    `).allContentfulAboutPageCard.edges

    const cards = cardsData.map((cardData, index) => (
        <Card key={cardData.node.id} reverse={!index % 2 ? true : false}>
            <Card.Heading>{cardData.node.heading}</Card.Heading>
            <Card.Thumbs
                imgSources={[].concat(cardData.node.image.file.url) || []}
            ></Card.Thumbs>
            <Card.Body>
                {documentToReactComponents(cardData.node.body.json)}
            </Card.Body>
        </Card>
    ))

    return (
        <>
            <Head title="About" />
            <AboutPageContainer>{cards}</AboutPageContainer>
        </>
    )
}

export default AboutPage
